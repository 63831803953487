// 根据process.env.NODE_ENV
// 开发环境：development
// 生产环境：production
// 测试环境：test

let BASE_URL = ''
const TIME_OUT = 10000
let DevDownloadAPI = ''
let USER_LOGIN_IP = ''

if (process.env.NODE_ENV === 'development') {
  BASE_URL = '/api'
  DevDownloadAPI = '/api'
  USER_LOGIN_IP = 'http://172.16.51.166:9001'
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = '/api'
  DevDownloadAPI = '/api'
  // USER_LOGIN_IP = 'http://172.16.51.166:9001'
  USER_LOGIN_IP = 'https://www.uniaccount.org'
} else {
  BASE_URL = ''
}

export { BASE_URL, TIME_OUT, DevDownloadAPI, USER_LOGIN_IP }

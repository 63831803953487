import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-38424b82"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "user-info"
};
const _hoisted_2 = {
  class: "el-dropdown-link"
};
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import { useRouter, useRoute } from 'vue-router';
import useUserStore from '@/store/modules/user.js';
import { storeToRefs } from 'pinia';
export default {
  __name: 'user-info',

  setup(__props) {
    const userStore = useUserStore();
    const {
      userInfo
    } = storeToRefs(userStore);

    const handleExitClick = () => {
      userStore.initUserExit();
      ElMessage({
        message: '退出登录成功！',
        type: 'success'
      });
    };

    const handleUserManagerClick = () => {// router.push('/userManager')
    };

    return (_ctx, _cache) => {
      const _component_el_avatar = _resolveComponent("el-avatar");

      const _component_arrow_down = _resolveComponent("arrow-down");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_CircleClose = _resolveComponent("CircleClose");

      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");

      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");

      const _component_el_dropdown = _resolveComponent("el-dropdown");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_dropdown, null, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            onClick: handleExitClick
          }, {
            default: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_CircleClose)]),
              _: 1
            }), _createTextVNode(" 退出登录")]),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => {
          var _unref2;

          return [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_avatar, {
            size: 30,
            src: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
          }), _createElementVNode("span", null, _toDisplayString((_unref2 = _unref(userInfo)) === null || _unref2 === void 0 ? void 0 : _unref2.username), 1), _createVNode(_component_el_icon, {
            class: "el-icon--right"
          }, {
            default: _withCtx(() => [_createVNode(_component_arrow_down)]),
            _: 1
          })])];
        }),
        _: 1
      })]);
    };
  }

};
import KHrequest from './request'

import { BASE_URL, TIME_OUT } from './request/config'

const request = new KHrequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      const token = window.localStorage.getItem('token')
      if (token) {
        config.headers = { Authorization: `Bearer ${token}` }
      }
      return config
    },
    requestInterceptorCatch: (err) => {
      // console.log('请求失败拦截')
      return err
    },
    responseInterceptor: (res) => {
      // console.log('请求成功回应的拦截')
      return res
    },
    responseInterceptorCatch: (err) => {
      // console.log('请求成功回应的拦截')
      return err
    }
  }
})

export default request

import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-03903915"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "header-pc"
};
const _hoisted_3 = {
  class: "header-mobile"
};
import HeaderMobile from './header-mobile/header-mobile.vue';
import HeaderPc from './header-pc/header-pc.vue';
export default {
  __name: 'Header',

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(HeaderPc)]), _createElementVNode("div", _hoisted_3, [_createVNode(HeaderMobile)])]);
    };
  }

};
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4f8858a0"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "social-icon"
};
export default {
  __name: 'social-icon-mobile',

  setup(__props) {
    const handleTo = path => {
      location.href = path;
    };

    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "gongzhonghao icon-parent",
        onClick: _cache[0] || (_cache[0] = $event => handleTo('https://www.comentropy.org/wp-content/uploads/2023/09/gongzhonghao-new.png'))
      }), _createElementVNode("div", {
        class: "shipinhao icon-parent",
        onClick: _cache[1] || (_cache[1] = $event => handleTo('https://www.comentropy.org/wp-content/uploads/2023/09/shipinghao-new.png'))
      }), _createElementVNode("div", {
        class: "qiyeweixin icon-parent",
        onClick: _cache[2] || (_cache[2] = $event => handleTo('https://www.comentropy.org/wp-content/uploads/2023/09/qun-new.png'))
      })]);
    };
  }

};
import { defineStore } from 'pinia'
import { verifyTickey, verifyToken } from '@/service/user/login.js'
import router from '@/router/index'
import { ElMessage } from 'element-plus'

const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: {},
    token: ''
  }),
  // 所有数据持久化
  // persist: true,
  // 持久化存储插件其他配置
  persist: true,
  // persist: {
  //   // 修改存储中使用的键名称，默认为当前 Store的 id
  //   key: 'storekey',
  //   // 修改为 sessionStorage，默认为 localStorage
  //   storage: window.localStorage,
  //   // 部分持久化状态的点符号路径数组，[]意味着没有状态被持久化(默认为undefined，持久化整个状态)
  //   paths: ['nested.data']
  // },

  getters: {},
  actions: {
    // 登录
    async verifyTicket(data) {
      const res = await verifyTickey(data)

      const goto = res.result.goto

      const verifyTokenRes = await verifyToken({
        token: res.result.token,
        user_id: res.result.user_id
      })

      this.userInfo = {
        username: verifyTokenRes.result.username,
        user_id: verifyTokenRes.result.user_id
      }
      this.token = verifyTokenRes.result.token

      window.localStorage.setItem('token', JSON.stringify(this.token))
      window.localStorage.setItem('userInfo', JSON.stringify(this.userInfo))

      ElMessage({
        message: '登录成功！',
        type: 'success'
      })

      const params = new URL(goto)
      const pathName = params.pathname
      router.replace(pathName)
    },

    // 验证token是否过期
    async verifyToken() {
      const res = await verifyToken({
        token: this.token,
        user_id: this.userInfo.user_id
      })

      // 如果过期
      if (!res.success) {
        this.initUserExit()
      }
    },
    // 退出登录
    initUserExit() {
      this.$reset()
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('userInfo')
    }
  }
})

export default useUserStore

import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import AppHeader from '@/components/Header/Header';
import AppFooter from '@/components/Footer/Footer';
import useUserStore from '@/store/modules/user';
export default {
  __name: 'App',

  setup(__props) {
    const userStore = useUserStore();
    userStore.verifyToken();
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_unref(ElConfigProvider), {
        locale: _unref(zhCn)
      }, {
        default: _withCtx(() => [_createVNode(_unref(AppHeader)), _createVNode(_component_router_view), _createVNode(_unref(AppFooter))]),
        _: 1
      }, 8, ["locale"])]);
    };
  }

};
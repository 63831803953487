import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '',
    redirect: `/item-list`,
    name: `Redirect`
  },
  {
    path: '/item-list',
    name: 'List',
    component: () => import('@/views/list/list.vue'),
    meta: {
      title: '下载中心｜深圳市共熵产业与标准创新服务中心'
    }
  },
  {
    path: '/item-detail/:id',
    name: 'Detail',
    component: () => import('@/views/detail/detail.vue')
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('@/views/callback/callback.vue')
    // meta: {
    //   title: '详情｜深圳市共熵产业与标准创新服务中心'
    // }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test.vue')
  },
  {
    path: '/:pathMath(.*)*',
    name: 'not-found',
    component: () => import('@/views/not-found/not-found.vue'),
    meta: {
      title: '找不到该页面｜深圳市共熵产业与标准创新服务中心'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router

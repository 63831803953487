import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2a208bac"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "footer"
};
const _hoisted_2 = {
  class: "footer-pc"
};
const _hoisted_3 = {
  class: "footer-mobile"
};
import FooterMobile from './footer-mobile/footer-mobile.vue';
import FooterPc from './footer-pc/footer-pc.vue';
export default {
  __name: 'Footer',

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(FooterPc)]), _createElementVNode("div", _hoisted_3, [_createVNode(FooterMobile)])]);
    };
  }

};
import request from '@/service/index'

// 验证ticket
export function verifyTickey(data) {
  return request.post({
    url: '/ticket',
    data
  })
}

export function verifyToken(data) {
  return request.post({
    url: '/token',
    data
  })
}
